.card-additional {
    &__content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include md-block {
            flex-direction: row;
        }
    }

    &__image {
        width: 107px;
        height: 107px;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;
    }
    &__inner {
        overflow: hidden;
    }

    &__price-old {
        @include caption();
        text-decoration: line-through;
        color: var(--color-typo);
    }

    &__price {
        @include h5();
        color: var(--color-black);
    }

    &__title {
        @include p();
        color: var(--color-black);
        max-height: 57px;
        overflow: hidden;
        padding-top: 6px;
    }

}