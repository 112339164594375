@import "import/normalize";
@import "../../../node_modules/@fancyapps/ui/src/Fancybox/Fancybox.scss";

.carousel {
    &__dot {

        &:after {
            background: var(--conic-gradient)!important;
            width: 10px;
            height: 10px;
        }
    }

    &__dots {
        top: calc(100% + 22px);
    }

    &.has-dots {
        margin-bottom: 40px;
    }

    &__button {
        &.is-prev {
            left: 35px;

            @include lg-block {
                left: 65px;
            }
        }

        &.is-next {
            right: 15px;

            @include lg-block {
                right: 65px;
            }
        }

        & svg {
            stroke: none;
            width: 100%;
            height: 100%;
        }

        &.is-close {
            top: 50px !important;
            right: 20px !important;

            & svg path {
                fill: var(--color-black);
            }
        }
    } 
}