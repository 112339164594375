.footer {
    @include wrapper();

    padding-top: 50px;
    align-self: stretch;
    margin-top: auto;
    width: 100%;

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 22px;
        padding: 30px 50px;
        border-radius: 20px;
        background-color: var(--color-bg-light);

        @include md-block {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &__copy {
        @include caption();
        color: var(--color-typo);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__social {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__address {
        @include caption();
        color: var(--color-typo);
        display: flex;
        align-items: center;
        justify-content: center;

        @include md-block {
            width: 378px;
        }
    }
}