.not-found {
    min-height: 100vh;
    background: url("/img/404.jpg") center no-repeat;
    background-size: cover;
    position: relative;

    &__logo {
        position: absolute;
        left: 50px;
        top: 5px;
    }

    &__code {
        @include p();
        position: absolute;
        right: 50px;
        bottom: 50px;
        color: var(--color-typo);
    }
}