.section {
    overflow: hidden;

    &__head {
        @include wrapper();
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        align-items: flex-start;

        @include md-block {
            flex-direction: row;
            align-items: center;
        }
    }

    &__title {
        & h2 {
            @include h5();

            @include md-block {
                @include h2();
            }
        }
    }

    &__link {
        @include link();
        @include gradient-text();
        text-decoration: none;
        margin: 5px 0 0;

        @include md-block {
            margin: 0 0 0 auto;
        }
    }
}
