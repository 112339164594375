.infoblock {
    background: var(--color-bg-dark);
    color: var(--color-white);
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 9px 3px rgba(1, 20, 35, 0.1), 0px 1px 3px rgba(1, 20, 35, 0.1);

    &__title {
        @include p();
        @include strong();
        margin: 0 0 12px;
    }

    &__text {
        @include p();
    }
}