.cart {
    padding: 30px 0 0;

    &__wrapper {
        @include wrapper();
    }

    &__head {
        margin: 0 0 20px;

        & h1, & h2 {
            @include h4();
        }
    }

    &__content {
        @include lg-block {
            @include row-flex();
        }
    }

    &__items {
        @include md-block {
            gap: 40px;
        }

        @include lg-block {
            @include col();
            @include size(9);
        }

        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    &__total {
        @include lg-block {
            @include col();
            @include size(3);
        }

        &-inner {
            background: var(--color-bg-light);
            border-radius: 15px;
            overflow: hidden;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
        }

        &-counter {
            @include h5();
            color: var(--color-black);
        }

        &-price {
            @include h4();
            color: var(--color-black);

            &-old {
                @include p();
                color: var(--color-typo);
                text-decoration: line-through;
            }
        }

        &-promo {
            align-self: stretch;

            & svg use {
                fill: var(--color-typo-disable);
            }
        }

        &-buy {
            align-self: stretch;
        }

        &-buttons {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 24px;
        }
    }


}