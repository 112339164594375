.breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: var(--color-typo);
    margin-bottom: 30px;

    &__item {
        & svg use {
            fill: var(--color-typo);
        }

        &:last-child {
            cursor: default;
        }
    }

    &__link {
        text-decoration: none;
        color: inherit;

        &:hover {
            @include gradient-text();

            svg use {
                fill: #e11735;
            }
        }
    }
}