.radiogroup {
    display: flex;

    &_text {
        gap: 4px;
    }

    &_color {
        gap: 10px;
        flex-wrap: wrap;

        & > .radio {
            width: 30px;
            height: 30px;
            flex: 0 0 30px !important;
            flex-wrap: wrap;

            .radio__box {
                width: 25px;
                height: 25px;
                border-radius: 50%;
            }

            .radio__input:checked + .radio__box {
                box-shadow: 0 0 0 3px var(--color-primary-500);
            }
        }
    }

    & > .radio {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 40px
    }
}