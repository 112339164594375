.radio {
    $c: &;

    width: 100%;

    &__input {
        display: none;

        &:checked + #{$c}__box {
            box-shadow: inset 0 0 0 3px var(--color-primary-500);
        } 
    }

    &__box {
        @include caption();
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        color: var(--color-typo-dark);
        box-shadow: inset 0 0 0 1px var(--color-bg-border);
        text-align: center;
        cursor: pointer;
        transition: box-shadow .2s ease;
    }
}