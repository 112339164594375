.filters {
    background-color: var(--color-bg-light);
    max-width: 330px;

    &__controls {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 30px;
        gap: 10px;
    }
}