.tabs {
    &__head {
        display: flex;
        border-bottom: 1px solid var(--color-bg-border);
    }

    &__tab {
        margin-bottom: -1px;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        padding: 8px 16px;
        transition: border .2s ease, color .2s ease;
        color: var(--color-typo);

        &:hover {
            color: var(--color-black);
        }

        &.is-active {
            color: var(--color-black);
            border-color: var(--color-primary-500);
        }
    }

    &__content {
        position: absolute;
        opacity: 0;
        transition: opacity .1s ease, transform .3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transform: translateY(50px);
        transform-origin: center top;
        padding: 30px 0;
        pointer-events: none;

        &.is-active {
            position: static;
            opacity: 1;
            transform: translateY(0);
            pointer-events: all;
        }
    }
}