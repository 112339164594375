.wiki {
    padding: 30px 0 0;

    &__wrapper {
        @include wrapper();
    }

    &__content {
        @include row-flex();
        align-items: flex-start;
        position: relative;
    }

    &__sidebar {
        @include col();
        @include size(12);

        @include md-block {
            @include size(2);
        }

        @include lg-block {
            @include size(3);
            position: sticky; // static
            top: 0;
        }
    }

    &__title {
        margin: 0 0 48px;
        
        & h1 {
            @include h4();
        }
    }

    &__nav {
        display: flex;
        flex-wrap: nowrap;
        margin: 0 0 20px;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 4px;

        @include md-block {
            margin: 0;
            display: block;
            padding-bottom: 0;
        }

        &-item {
            padding: 12px;
            border-radius: 10px;
            white-space: nowrap;

            &_active {
                box-shadow: inset 0 0 0 3px #E21A38; //TODO: conic gradient

                .wiki__nav-link {
                    @include gradient-text();
                }
            }

            @include md-block {
                text-align: right;
            }

            @include lg-block {
                padding: 12px 24px;
            }
        }

        &-link {
            @include h5();
            text-decoration: none;
            color: var(--color-black);

            @include lg-block {
                &:hover {
                    @include gradient-text();
                }

                & svg {
                    display: none;
                }
            }
        }
    }

    &__main {
        @include col();
        @include size(12);

        @include md-block {
            @include size(10);
            padding-left: 50px;
        }

        @include lg-block {
            @include size(9);
        }
    }

    &__list {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 12px;
    }

    &__link {
        @include p();
        @include strong();
        color: var(--color-black);
        text-decoration: none;
    }
}