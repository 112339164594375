* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}

html, body {
    min-height: 100vh;
}

body {
    @include reset();
    font-family: Averta, sans-serif;
    &.no-scroll {
        overflow: hidden;
    }
    display: flex;
    flex-direction: column;
}

::selection {
    background-color: var(--color-primary-400);
    color: var(--color-white);
}

hr {
    border: 0;
    border-bottom: 1px solid var(--color-bg-border);
    margin: 30px 0;
}

.smart-grid {
    @include debug(rgba(30, 30, 215, 0.1), transparent);
}

::-webkit-scrollbar {
    width: 6px; 
    height: 6px;              /* width of the entire scrollbar */
  }
  
::-webkit-scrollbar-track {
    background: transparent;
  }
  
::-webkit-scrollbar-thumb {
    background-color: transparent;    /* color of the scroll thumb */
    border-radius: 20px;  
    background: var(--color-primary-400);        /* color of the tracking area */
         /* roundness of the scroll thumb */
}

.disable-hover,
.disable-hover * {
    pointer-events: none !important;
}