.auth {
    max-height: 100vh;
    height: 100vh;

    &__wrapper {
        @include wrapper();
        height: 100%;
    }

    &__back {
        @include gradient-text();
        @include caption();
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
        -webkit-tap-highlight-color: transparent;

        & svg use {
            fill: var(--color-primary-500);
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 20px);
    }

    &__form {
        width: 350px;
        margin: auto;
    }
}