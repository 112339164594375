.modal {
    $c: &;

    position: fixed;
    z-index: 1000;
    opacity: 1;
    transition: opacity .3s ease;

    &_default {
        width: 100%;
        min-height: 100vh;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        

        &.hidden {
            pointer-events: none;
            opacity: 0;

            #{$c}__container {
                @include md-block {
                    transform: translateX(100%);
                }
            }
        }
    }

    &__shadow {
        position: absolute;
        z-index: -1;
        background: #001424;
        opacity: 0.2;
        height: 100%;
        width: 100%;
    }

    &__container {
        position: relative;
        z-index: 1001;
        margin-left: auto;
        background: var(--color-white);
        transform: translateX(0);
        transition: transform .3s ease;
        padding: 50px 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include md-block {
            padding: 50px;
            width: 537px;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        margin-bottom: auto;
        margin-top: auto
    }

    &__close {
        border: 0;
        background: none;
        padding: 0;
        margin-left: auto;
        margin-bottom: 19px;

        @include lg-block {
            margin-bottom: 47px;
        }

        @include lg-block {
            margin-bottom: 72px;
        }
    }

    &__title {
        @include h4();
        margin: auto 0 20px;

        @include md-block {
            margin: auto 0 30px;
        }

        &:last-child {
            margin-bottom: auto;
        }
    }

    &__form {
        width: 100%;
        margin-bottom: auto;

        &-fields {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &-submit {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin: 20px 0 0;
        }

        &-agreement {
            @include caption();
            color: var(--color-typo);
            margin: 8px 0 0;
        }
    }
}