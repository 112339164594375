.map-main {
    margin: 0 0 20px;

    &__wrapper {
        @include wrapper();
        display: flex;
        flex-direction: column;
        position: relative;

        @include md-block {
            flex-direction: row;
            align-items: center;
        }
    }

    & #map-main {
        height: 505px;
        border-radius: 20px;
        overflow: hidden;
        margin: -25px 0 0;

        @include md-block {
            width: calc(100% - 380px);
            margin: 0 0 0 auto;
        }
    }

    &__legend {
        display: flex;
        flex-direction: column;
        background: var(--color-bg-light);
        padding: 50px 40px;
        align-items: flex-start;
        border-radius: 20px;

        @include md-block {
            width: 474px;
            position: absolute;
            z-index: 1;
        }
    }

    & h2 {
        @include h5();
        margin: 0 0 30px;

        @include md-block {
            @include h2();
            white-space: nowrap;
        }
    }

    &__address {
        @include strong();
        max-width: 180px;
        margin: 0 0 15px;

        @include md-block {
            @include h5();
            max-width: 215px;
        }
    }

    &__caption {
        @include caption();
        color: var(--color-typo);
        margin: 0 0 10px;
    }

    &__phone {
        @include strong();
        color: var(--color-black);
        text-decoration: none;
        margin: 0 0 8px;

        @include md-block {
            @include h5();
        }
    }

    &__btn {
        margin: 22px 0 0;
    }
}