.brand {
    $c: &;

    width: 100%;
    height: 100%;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 9px;
    text-decoration: none;
    background: var(--color-white);
    color: var(--color-typo);

    &:hover {
        background: var(--conic-gradient);
        color: white;
        border-radius: 10px;
        overflow: hidden;

        #{$c}__icon use {
            fill: var(--color-white);
        }
    }

    @include md-block {
        min-height: 150px;
    }

    @include md-block {
        min-height: 155px;
    }

    &__icon use {
        fill: var(--color-typo);
    }

    &__title {
        
    }
}