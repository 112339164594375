.auth-form {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__title {
        @include h4();
        color: var(--color-typo-dark);
        text-align: center;
        margin: 0 0 50px;
    }

    &__switch {
        display: flex;
        margin: 0 0 50px;
        gap: 24px;

        &-item {
            @include h4();
            color: var(--color-typo);
            text-decoration: none;
            -webkit-tap-highlight-color: transparent;

            &_active,  &:hover {
                @include gradient-text();
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__buttons {
        margin: 14px 0 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__policy {
        @include caption();
        color: var(--color-typo);

        & a {
            color: inherit;
        }
    }
}