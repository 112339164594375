.favorites {
    padding: 30px 0 0;

    &__wrapper {
        @include wrapper();
    }

    &__title {
        @include h4();
        margin: 0 0 20px;
    }

    &__total {
        padding: 20px;
        background-color: var(--color-bg-light);
        border-radius: 15px;
        box-shadow: 0px 4px 9px 3px rgba(1, 20, 35, 0.1), 0px 1px 3px rgba(1, 20, 35, 0.1);

        &-head {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 0 16px;

            @include md-block {
                flex-direction: row;
                align-items: center;
            }

            h5 {
                @include h5();
                margin: 0 0 16px;

                @include md-block {
                    margin: 0;
                }
            }
        }
    }

    &__btns {
        display: flex;
        gap: 8px;
    }

    &__content {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 50px;

        @include md-block {
            margin-top: 70px;
        }

        @include lg-block {
            gap: 70px;
        }
    }
}