.lk {
    padding: 30px 0 0;

    &__wrapper {
        @include wrapper();
    }

    &__content {
        @include row-flex();
        align-items: flex-start;
        position: relative;
    }

    &__sidebar {
        @include col();
        @include size(12);

        @include lg-block {
            @include size(4);
            position: sticky; // static
            top: 0;
        }
    }

    &__name {
        margin: 0 0 48px;
        @include h4();
        
        & h1 {
            @include md-block {
                @include h2();
            }
        }

        @include lg-block {
            text-align: right;
        }
    }

    &__nav {
        &-item {
            padding: 12px 0;
            border-radius: 10px;

            @include lg-block {
                padding: 12px 24px;
                text-align: right;

                &_active {
                    box-shadow: inset 0 0 0 3px #E21A38; //TODO: conic gradient
    
                    .lk__nav-link {
                        @include gradient-text();
                    }
                }
            }
        }

        &-link {
            @include h5();
            text-decoration: none;
            color: var(--color-black);

            @include lg-block {
                &:hover {
                    @include gradient-text();
                }

                & svg {
                    display: none;
                }
            }
        }

        &-arrow {
            display: inline-block;
            margin-left: 12px;

            & use {
                fill: var(--color-typo);
            }
        } 
    }

    &__main {
        @include col();
        @include size(12);

        @include lg-block {
            @include size(8);
            padding-left: 50px;
        }
    }

    &__title {
        @include h5();
        margin: 0 0 30px;
    }

    &__form {
        width: 335px;
        &:not(:last-child) {
            margin: 0 0 60px;
        }

        &-fields {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
        &-submit {
            display: flex;
            justify-content: flex-end;
        }
    }
}