.button {
    white-space: nowrap;
    &:not(:disabled) {
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }

    &_primary {
        padding: 10px;
        background: var(--conic-gradient);
        border: 1px solid transparent;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        &:hover {
            background: #e11735;
            box-shadow: 4px 0px 9px 3px rgba(1, 20, 35, 0.1);
            filter: drop-shadow(1px 0px 3px rgba(1, 20, 35, 0.1));
        }

        &:focus {
            outline: none;
            background: #e11735;
        }

        &:active {
            background: var(--conic-gradient);
        }

        &:disabled {
            color: var(--typo-disable);
            background: var(--color-bg-border);
            pointer-events: none;
        }

        & svg use {
            fill: var(--color-white);
        }
    }

    &_has-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    &_round {
        border: 1px solid transparent;
        background: rgba(255,255,255,.4);
        width: 46px;
        height: 46px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg use {
            fill: var(--color-primary-700);
        }

        &:hover {
            box-shadow: 0px 4px 9px 3px rgba(1, 20, 35, 0.1);
            filter: drop-shadow(0px 1px 3px rgba(1, 20, 35, 0.1));
            background: #fff;

            svg use {
                fill: #e11735;
            }
        }

        &:focus, &:active {
            outline: none;
            background: #fff;

            svg use {
                fill: #e11735;
            }
        }

        &:disabled {
            background: var(--color-bg-disabled);
            pointer-events: none;

            svg use {
                fill: var(--color-typo-disable);
            }
        }
    }

    &_outlined {
        @include link();
        @include caption();

        padding: 10px;
        background: transparent;
        border: 1px solid var(--color-bg-border);
        border-radius: 10px;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
        gap: 10px;

        & svg use {
            fill: var(--color-typo);
        }

        &:hover {
            @include gradient-text();
            border: 1px solid #e11735;

            & svg use {
                fill: #e11735;
            }
        }

        &.button_check {
            & svg use {
                fill: #e11735;
            }
        }
    }

    &_is-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        &:not(.button_outlined) {
            border: 1px solid transparent !important;
        }

        &:hover {
            &:not(.button_outlined) {
                border: 1px solid transparent !important;
            }

            & svg use {
                fill: #e11735;
            }
        }

        &:active, &.active {
            border: 1px solid #e11735 !important; // Перебить стили от button_outlined

            & svg use {
                fill: #e11735;
            }
        }
    }

    &_toggle {
        background: none;
        border: 0;
        display: flex;
        align-items: center;
        padding: 0;

        & svg use {
            fill: var(--color-typo);
        }

        &:hover {
            & svg use {
                fill: #e11735;
            }
        }

        &_on {
            & svg:nth-child(1) {
                display: block;
            }
    
            & svg:nth-child(2) {
                display: none;
            }
        }

        &_off {
            & svg:nth-child(1) {
                display: none;
            }
    
            & svg:nth-child(2) {
                display: block;
            }
        }
    }

    &_text {
        background: none;
        border: 0;

        & span {
            @include caption();
            @include gradient-text();
            font-weight: 600;
        }
    }
    &_like {
        display: flex;
        align-items: center;
        gap: 8px;
        
        & svg use {
            fill: var(--color-typo);
        }

        span {
            @include p();
            color: var(--color-typo);
        }
    }

    &_link {
        @include caption();
        @include gradient-text();
        font-weight: 600;
        border: 0;
    }

    &_center {
        text-align: center;
        justify-content: center;
    }
}