.review {
    &__inner {
        padding: 30px 20px;

        @include md-block {
            padding: 30px 50px;
        }

        @include md-block {
            padding: 30px 126px;
        }
    }

    &__name {
        @include h5();
        color: var(--color-typo-dark);
    }

    &__date {
        @include caption();
        color: var(--color-typo);
        margin: 0 0 24px;
    }

    &__block {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    &__title {
        @include h5();
        color: var(--color-typo);
        margin: 0 0 12px;
    }

    &__text {
        @include p();
        color: var(--color-typo-dark);
    }

    &__likes {
        display: flex;
        align-items: center;
        gap: 24px;
        margin: 24px 0 0;
    }
}