.card-news {
    display: flex;
    flex-direction: column;

    &__image {
        border-radius: 15px;
        overflow: hidden;
        max-height: 300px;
        display: flex;
        align-items: center;

        @include md-block {
            max-height: 360px;
        }

        & img {
            max-width: 100%;
            width: 100%;
            height: auto;
        } 
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 24px 0 0;
    }

    &__date {
        @include caption();
        color: var(--color-typo);
    }

    &__title {
        @include strong();
        color: var(--color-black);
    }

    &__text {
        @include caption();
        color: var(--color-black);
    }
}