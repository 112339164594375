.popular {
    margin-bottom: 60px;

        @include md-block {
            margin-bottom: 100px;
        }
        
    &-slider {
        padding-left: 20px;
        

        @include lg-block {
            @include wrapper();
        }

        &__item {
            width: 268px;
            padding: 0;
            margin-right: 40px;
        }
    }
}