.textarea {
    $c: &;
    width: 100%;

    &_disabled {
        pointer-events: none;

        #{$c}__box {
            background: var(--color-bg-disabled);
            border-color: var(--color-bg-disabled);
            color: var(--color-typo-disable);
        }
    }

    &_error {
        #{$c}__box {
            border-color: #EB5757;
        }

        #{$c}__error {
            display: block;
            margin-top: 2px;
            color: #EB5757;
        }
    }

    &__box {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 11px 12px;
        border: 1px solid var(--color-bg-border);
        border-radius: 8px;
    }

    &__error {
        font-size: 12px;
    }

    & textarea {
        @include p();
        line-height: 24px;
        width: 100%;
        background: none;
        border: 0;
        color: inherit;
        font-family: inherit;
        resize: none;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: var(--color-typo);
        }
    }
}