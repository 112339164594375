.checkbox {
    $c: &;

    &_error {
        #{$c}__box {
            border-color: #EB5757 !important;
        }
    }

    &_disabled {
        pointer-events: none;

        #{$c}__box {
            background: var(--color-bg-disabled);
            border-color: var(--color-bg-disabled);
            color: var(--color-typo-disable);
        }

        #{$c}__label {
            color: var(--color-typo-disable);
        }
    }

    &__block {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        cursor: pointer;
    }

    &__input {
        display: none;

        &:checked + #{$c}__box {
            background: var(--color-black);
            border: 1px solid transparent;

            & #{$c}__tick {
                display: block;
            }
        }
    }

    &__box {
        flex-shrink: 0;
        border: 1px solid var(--color-bg-border);
        width: 16px;
        height: 16px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
    }

    &__label {
        @include caption();
        color: var(--color-black);
    }

    &__tick {
        display: none;

        use {
            fill: var(--color-primary-400);
        }
    }

    &__error {
        font-size: 12px;
        color: #EB5757 !important;
    }
}