.brands {
    padding: 30px 0 0;
    margin: 0 0 60px;

    @include md-block {
        margin: 0 0 100px;
    }

    &__wrapper {
        @include wrapper();
    }

    &__grid {
        $columns-md: 3;
        $columns-lg: 4;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        align-content: stretch;
        align-items: stretch;
        
        @include md-block {
            grid-template-columns: repeat(3, calc(100% / 3));
        }

        @include lg-block {
            grid-template-columns: repeat(4, 25%);
        }
    }

    &__item {
        border-bottom: 1px solid var(--color-bg-border);
        border-left: 1px solid var(--color-bg-border);
        -webkit-tap-highlight-color: transparent;

        &:nth-last-child(1), &:nth-last-child(2) {
            border-bottom-color: transparent;
        }

        &:nth-child(2n+1) {
            border-left-color: transparent;
        }

        @include md-block {

            &:nth-last-child(3) {
                border-bottom-color: transparent;
            }

            &:nth-child(2n+1) {
                border-left-color: var(--color-bg-border);
            }
            &:nth-child(3n+1) {
                border-left-color: transparent;
            }
        }

        @include lg-block {
            &:nth-last-child(4) {
                border-bottom-color: transparent;
            }

            &:nth-child(3n+1) {
                border-left-color: var(--color-bg-border);
            }
            &:nth-child(4n+1) {
                border-left-color: transparent;
            }
        }
    }
}