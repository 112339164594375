@font-face {
    font-family: 'Averta';
    src:  url('../../fonts/Averta-Cyrillic-Regular.woff2') format('woff2'),
          url('../../fonts/Averta-Cyrillic-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
@font-face {
    font-family: 'Averta';
    src:  url('../../fonts/Averta-Cyrillic-SemiBold.woff2') format('woff2'),
          url('../../fonts/Averta-Cyrillic-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }
@font-face {
    font-family: 'Averta';
    src:  url('../../fonts/Averta-Cyrillic-Bold.woff2') format('woff2'),
          url('../../fonts/Averta-Cyrillic-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }