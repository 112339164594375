.card-favorites {
    display: flex;
    flex-direction: column;

    @include md-block {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 50px;
    }

    &__check {
        flex-shrink: 0;
    }

    &__image {
        width: 100%;

        @include md-block {
            width: 124px;
        }

        @include lg-block {
            width: 232px;
        }
    }

    &__content {
        @include md-block {
            flex: 1 0 515px;
        }
    }

    &__top {
        @include caption();
        color: var(--color-typo);
        display: flex;
        gap: 16px;
    }

    &__title {
        @include h5();
        margin: 0 0 8px;
    }

    &__text {
        @include p();
        color: var(--color-black);
        margin: 0 0 24px;
        overflow: hidden;
        max-height: 100%;

        &.is-shrinked {
            background: -webkit-linear-gradient(var(--color-black) 60%, transparent 90%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            max-height: 54px;

            @include lg-block {
                max-height: 87px;
            }

            @include lg-block {
                max-height: 156px;
            }
        }

        
    }

    &__tags {
        margin: 0 0 16px;
    }

    &__avail {
        &_yes {
            color: var(--color-success)
        }
        &_no {
            color: var(--color-error)
        }
    }

    &__buy {
        width: 100%;

        @include md-block {
            flex-shrink: 0;
            width: 164px;
        }

        @include lg-block {
            width: 232px;
        }
    }

    &__price {
        @include h4();

        &-old {
            @include p();
            color: var(--color-typo);
            text-decoration: line-through;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}