.input {
    $c: &;
    width: 100%;

    &_disabled {
        pointer-events: none;

        #{$c}__box {
            background: var(--color-bg-disabled);
            border-color: var(--color-bg-disabled);
            color: var(--color-typo-disable);
        }
    }

    &_error {
        #{$c}__box {
            border-color: #EB5757;
        }

        #{$c}__error {
            display: block;
            margin-top: 2px;
            color: #EB5757;
        }
    }

    &_small {
        #{$c} {
            &__box {
                padding: 5px 19px;

            }

            
        }

        & input {
            @include caption();
            font-size: 14px !important;
        }
    }

    &__box {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 11px 12px;
        border: 1px solid var(--color-bg-border);
        border-radius: 8px;
        transition: box-shadow .2s ease, border .2s ease;
        background: var(--color-white);

        &.has-focus {
            border-color: var(--color-typo);
            box-shadow: 0px 1px 3px 0px rgba(1, 20, 35, 0.1);
            box-shadow: 0px 4px 9px 3px rgba(1, 20, 35, 0.1);
            background-color: var(--color-white);
        }
    }

    &__error {
        font-size: 12px;
    }

    & input {
        @include p();
        line-height: 24px;
        width: 100%;
        background: none;
        border: 0;
        color: inherit;
        font-family: inherit;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: var(--color-typo);
        }
    }

    &__caption {
        @include caption();
        color: var(--color-typo-dark);
        margin-top: 4px;
    }

    &__label {
        @include caption();
        color: var(--color-typo);
        display: inline-block;
        margin-bottom: 8px;
    }
}