.additional {
    padding: 30px 0;

    &__wrapper {
        @include wrapper();
    }

    &__divider {
        margin: 0 0 30px;
    }

    &__title {
        @include h5();
        margin: 0 0 40px;

        @include md-block {
            @include h4();
        }
    }

    &__list {
        @include row-flex();
        flex-wrap: wrap;
    }

    &__item {
        @include col();
        @include size(6);
        margin-bottom: 40px; //TODO: handle last margins

        @include md-block {
            @include size(4);
        }

        @include lg-block {
            @include size(3);
        }
    }
}