.product {
    &__wrapper {
        @include wrapper();
    }

    &__content {
        @include md-block {
            @include row-flex();
        }
    }

    &__preview {
        @include md-block {
            @include col();
            @include size(9);
        }

        @include lg-block {
            @include size(5);
            order: 1;
        }
    }

    &__options {
        @include md-block {
            @include col();
            @include size(12);
            order: 2;
        }

        @include lg-block {
            @include size(4);
        }
    }

    &__buy {
        display: flex;
        flex-direction: column;

        @include md-block {
            @include col();
            @include size(3);
        }

        @include lg-block {
            order: 3;
        }
    }

    &__tabs {
        @include md-block {
            @include col();
            @include size(12);
            order: 3;
        }

        @include lg-block {
            order: 4;
        }
    }

    &__options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-bottom: 40px;

        @include md-block {
            margin-bottom: 100px;
        }

        @include lg-block {
            padding: 0 50px;
            margin-bottom: 86px;
        }
    }

    &__option {
        display: flex;
        gap: 16px;
        align-items: baseline;

        &-title {
            width: 80px;
            flex-shrink: 0;
        }

        &-values {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            flex-shrink: 1;
            width: calc(100% - 96px);
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        margin: 0 0 24px;
    }

    &__prices-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 0 8px;
    }

    &__prices {
        display: flex;
        flex-direction: column;
        margin: 0 0 8px;
    }

    &__price-old {
        @include caption();
        color: var(--color-typo);
        text-decoration: line-through;
    }

    &__price {
        @include h4();
        color: var(--color-black);
    }

    &__actions {
        display: flex;
        gap: 4px;
        margin: 8px 0 0;
    }

    &__tabs-title {
        @include h4();
        color: var(--color-typo-dark);
        margin: 0 0 32px;
    }
}