.menu-item {
    $c: &;

    &_has-icon {
        #{$c} {
            &__inner {
                padding: 9px 12px;
            }

            &__title {
                font-weight: 600;
            }
        }
    }

    &__inner {
        padding: 4px 0;
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__icon {
        & use {
            fill: var(--color-primary-500);
        }
    }

    &__title {
        @include caption();
    }

    &__arr {
        margin-left: auto;
        & svg use {
            fill: var(--color-typo);
        }
    }
}