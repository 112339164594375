.card-catalog {
    $c: &;
    display: flex;
    position: relative;

    &_view {
        &_grid {
            flex-direction: column;
        }

        &_row {
            flex-direction: row;
        }
    }

    &__fav {
        display: flex;
        flex-direction: row-reverse;
        margin: 0 0 8px;
    }

    &__image {
        margin: 0 0 12px;
        border-radius: 15px;
        overflow: hidden;
        height: 280px;

        &-long {
            margin: 0 0 12px;
            border-radius: 15px;
            overflow: hidden;
            height: 380px;

            #{$c}__tags {
                bottom: 229px;
            }
        }

        & img {
            max-width: 100%;
            width: 100%;
            height: auto;
        } 
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__brand {
        @include caption();
        color: var(--color-typo);
    }

    &__title {
        @include strong();
        color: var(--color-black);
    }

    &__avail {
        @include caption();

        &_yes {
            color: var(--color-success);
        }

        &_no {
            color: var(--color-primary-500);
        }
    }

    &__buy {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__prices {
        
    }

    &__price-old {
        @include caption();
        color: var(--colot-typo);
        text-decoration: line-through;
    }

    &__price {
        @include h5();
        color: var(--color-black);
    }

    &__tags {
        position: absolute;
        bottom: 252px;
        left: 15px;
        right: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 8px;
    }
}