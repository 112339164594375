.property {
    @include caption();
    width: 100%;
    display: flex;
    align-items: baseline;
    gap: 12px;
    padding: 8px 0;

    &__name {
        width: 50%;
        display: flex;
        align-items: center;
        gap: 12px;

        &:after {
            content: '';
            display: block;
            border-bottom: 1px dashed var(--color-bg-border);
            height: 1px;
            flex: 1;
        }
    }

    &__value {
        width: 50%;
    }
}