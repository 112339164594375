@mixin h1 {
    font-weight: 600;
    font-size: 66px;
    line-height: 90%;
}

@mixin h2 {
    font-weight: 600;
    font-size: 54px;
    line-height: 90%;
}

@mixin h3 {
    font-weight: 600;
    font-size: 44px;
    line-height: 100%;
}

@mixin h4 {
    font-weight: 600;
    font-size: 35px;
    line-height: 120%;
}

@mixin h5 {
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
}

@mixin strong {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
}

@mixin p {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
}

@mixin caption {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
}

@mixin link {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
}

@mixin gradient-text {
    background: var(--conic-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}



