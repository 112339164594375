.header {
    position: relative;
    z-index: 1000;

    &__wrapper {
        @include wrapper();
    }

    &__inner {
        @include lg-block {
            padding: 0 0 10px;
        }

        display: none;
        position: relative;

        @include lg-block {
            @include row-flex();
        }

        &-mobile {
            display: block;
            position: relative;
            z-index: 1000;

            @include lg-block {
                display: none;
            }
        }
    }

    &__logo {
        @include col();
        @include size(5);
        order: 0;

        @include md-block {
            @include size(3);
        }

        @include lg-block {
            @include size(2);
        }
    }

    &__contacts{
        @include col();
        @include size(5);
        @include shift-left(1);
        display: flex;
        align-items: center;
        order: 2;

        @include md-block {
            @include size(3);
            @include shift-left(0);
        }

        @include lg-block {
            order: 0;
            @include size(2);
        }
    }

    &__menu {
        display: none;
        @include lg-block {
            display: flex;
            @include col();
            @include size(7);
            @include shift-left(1);
        }
    }

    &__contact {
        @include caption();
        color: var(--color-typo);
        display: flex;
        justify-content: space-between;
        gap: 12px;
        max-width: 150px;

        @include md-block {
            max-width: none;
        }

        &-title {
            display: flex;
            align-items: center;
            gap: 6px;

            & svg use {
                fill: var(--color-typo);
                opacity: .5;
            }

            & span {
                display: none;

                @include md-block {
                    display: block;
                }
            }
        }

        & a {
            color: inherit;
            font: inherit;
            text-decoration: none;
        }
    }

    &__catalog {
        position: relative;

        &-btn {
            background-color: transparent;
            border: 0;
            display: flex;
            align-items: center;
            gap: 12px;
            font-weight: 600;
            font-size: 14px;  

            & svg use {
                fill: var(--color-primary-500);
            }

            & span {
                @include gradient-text();
            }
        }

        @include lg-block {
            display: block;
            @include col();
            @include size(3);

            &-btn {
                width: 100%;
                background-color: var(--color-bg-light);
                padding: 10px;
                color: var(--color-typo-dark);
                border-radius: 10px;
                height: 40px;
            }
        }
    }

    &__search-toggle-mobile {
        display: block;

        @include md-block {
            display: none;
        }
    }

    &__search {
        @include col();
        @include size(1);
        display: flex;
        align-items: center;
        order: 1;

        @include md-block {
            @include size(4);
            @include shift(1);
        }

        @include lg-block {
            order: 0;
            @include size(8);
            @include shift(0);
        }

        &-form {
            display: none;

            @include md-block {
                width: 100%;
                display: flex;
                gap: 10px;
                align-items: center;
                padding: 10px 24px;
                background-color: var(--color-bg-light);
                border-radius: 10px;
            }
        }

        & input {
            @include caption();
            height: 20px;
            display: none;
            border: 0;
            background: transparent;
            outline: none;

            &::placeholder {
                color: var(--color-typo);
            }

            @include md-block {
                display: inline-block;
                width: 100%;
            }
        }

        &-btn {
            height: 16px;
            width: 16px;
            background: transparent;
            border: 0;

            & svg use {
                fill: var(--color-typo);
            }
        }
    }
    &__actions {
        display: none;

        @include lg-block {
            @include col();
            @include size(1);
            display: flex;
            gap: 13px;
        }
    }

    &__action {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        & svg use {
            fill: var(--color-typo);
        }
    }

    &__messengers {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__mobile {
        &-top {
            @include wrapper();
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 50px;
            background: var(--color-white);
            box-shadow: 0px 1px 3px 0px #0114231A, 0px 4px 9px 3px #0114231A;
            display: flex;
            align-items: center;
        }

        &-bottom {
            @include wrapper();
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            background: var(--color-white);
            display: flex;
            align-items: center;
        }

        &-logo {
            height: 29.32px;
            width: 122px;
        }
    }
    
}

