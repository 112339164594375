.card-cart {
    width: 100%;
    
    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
    
        @include md-block {
            flex-direction: row;
            align-items: flex-start;
            gap: 50px;
        }
    }


    &__image {
        width: 100%;
        border-radius: 15px;
        overflow: hidden;

        @include md-block {
            width: 107px;
        }

    }

    &__content {
        @include md-block {
            flex: 0 1 375px;
        }
    }

    &__top {
        @include caption();
        color: var(--color-typo);
        display: flex;
        gap: 16px;
    }

    &__title {
        @include strong();
    }

    &__qty {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        width: 130px;

        &-action {
            width: 40px;
            height: 40px;

            & .button {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__price {
        &s {
        flex: 1;

        }

        @include h5();

        &-old {
            @include p();
            color: var(--color-typo);
            text-decoration: line-through;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    
}