.catalog-menu {
    $c: &;

    display: none;
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;

    &.visible {
        display: block;
    }

    @include lg-block {
        position: absolute;
        left: 10px;
        width: 360px;
        z-index: 1000;
    }

    &__main {
        background: var(--color-bg-light);
        border-radius: 10px;
    }

    &__children {
        display: none;
        width: 360px;
        box-shadow: 0px 1px 3px 0px #0114231A, 0px 4px 9px 3px #0114231A;
        padding: 12px 0;
        border-radius: 10px;
        background: var(--color-white);
        position: absolute;
        top: 0;
        left: 100%;

        &:hover {
            display: block;
        }
    }

    &__item {
        cursor: pointer;
        position: relative;

        @include lg-block  {
            &:hover, &_active {
                background: var(--color-white);
                 
                & > #{$c}__children {
                    display: block;
                }
            }
        }
    }

    &__sub-item {
        color: var(--color-black);
        padding: 0 16px;

        @include lg-block  {
            &:hover, &_active {
                 
                & > #{$c}__children {
                    display: block;
                }
            }
        }
    }

    &__super-item {
        color: var(--color-black);
        padding: 0 16px;
    }
}