.article {
    padding: 30px 0 0;

    &__wrapper {
        @include wrapper();
    }

    &__title {
        @include h5();
        margin: 0 0 50px;

        @include md-block {
            @include h4();
        }
    }

    &__inner {
        @include row-flex();
        align-items: flex-start;
        position: relative;
    }

    &__nav {
        display: none;
        
        @include lg-block {
            @include col();
            @include size(3);
            position: sticky; // static
            top: 0;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &-item {
            @include h5();
            text-decoration: none;
            color: var(--color-black);
            padding: 12px 24px;
        }
    }

    &__content {
        @include col();
        @include size(12);

        @include lg-block {
            @include size(7);
            @include shift(1);
        }
    }
}