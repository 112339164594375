.news {
    padding: 30px 0 0;

    &__wrapper {
        @include wrapper();
    }

    &__title {
        @include h5();
        margin: 0 0 20px;

        @include md-block {
            @include h4();
        }
    }

    &__list {
        @include row-flex();
        flex-wrap: wrap;
    }

    &__item {
        @include col();
        @include size(12);
        margin-bottom: 60px;

        &:last-child {
            margin-bottom: 0; //TODO: clear last items margin-bottom
        }

        @include md-block {
            @include size(6);
        }

        @include lg-block {
            @include size(4);

            &:nth-child(5n+1),
            &:nth-child(5n+2){
                @include size(6);
            }
        }
    }
}