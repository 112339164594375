.tag {
    $c: &;

    height: 26px;
    display: flex;

    &:before, &:after {
        content: '';
        width: 23px;
    }

    &:before {
        clip-path: path('M3 0a3 3 0 0 0-3 3v5.019c0 .567.408 1.052.967 1.15 4.297.747 4.297 6.915 0 7.663A1.167 1.167 0 0 0 0 17.982V23a3 3 0 0 0 3 3h20V0H3Z');
        margin-right: -0.2px;
    }

    &:after {
        clip-path: path('M20 0a3 3 0 0 1 3 3v5.019c0 .567-.408 1.052-.967 1.15-4.297.747-4.297 6.915 0 7.663.559.097.967.582.967 1.15V23a3 3 0 0 1-3 3H0V0h20Z');
        margin-left: -0.2px;
    }

    &_success {
        &:before, &:after {
            background: var(--color-bg-success);
        }

        #{$c}__text {
            color: var(--color-typo-success);
            background: var(--color-bg-success);
        }
    }

    &_error {
        &:before, &:after {
            background: var(--color-bg-error);
        }

        #{$c}__text {
            color: var(--color-typo-error);
            background: var(--color-bg-error);
        }
    }

    &_info {
        &:before, &:after {
            background: var(--color-bg-info);
        }

        #{$c}__text {
            color: var(--color-typo-info);
            background: var(--color-bg-info);
        }
    }

    &_warning {
        &:before, &:after {
            background: var(--color-bg-warning);
        }

        #{$c}__text {
            color: var(--color-typo-warning);
            background: var(--color-bg-warning);
        }
    }

    &__text {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 26px;
    }
}