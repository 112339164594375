.menu {
    display: flex;
    list-style: none;
    gap: 46px;
    width: 100%;
    
    &__item {
        padding: 21px 2px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        white-space: nowrap;
    }
}