.filter {
    padding: 15px 30px;

    &__title {
        @include p();
        @include strong();
        margin: 0 0 16px;
    }

    &__colors {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    &__color {
        width: 31px;
        height: 31px;
        padding: 3px;
        border-radius: 50%;
        cursor: pointer;
    }

    &__inputs-2 {
        display: flex;
        align-items: baseline;
        gap: 5px;
    }

    &__range-sign  {
        @include caption();
        color: var(--color-typo);
    }

    &__checkbox {
        &-list {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 8px;
        }

        &-item {
            &_hidden {
                display: none;
            }
        }
    }

    &__more {
        @include caption();
        @include gradient-text();
        display: inline-block;
        font-weight: 600;
        margin-top: 16px;
        cursor: pointer;
    }
}