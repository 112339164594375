.icon {
    &_small + &_default {
        display: none;

        @include md-block {
            display: block;
        }
    }

    @include md-block {
        &_small {
            display: none;
        }
    }
}