.contacts {
    padding: 30px 0 0;

    &__wrapper {
        @include wrapper();
    }

    &__head {
        margin: 0 0 20px;

        & h1, & h2 {
            @include h4();
        }
    }

    &__legend {
        background: var(--color-bg-light);
        border-radius: 15px;
        padding: 20px 20px 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include md-block {
            padding: 50px;
            width: 390px;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 33px;
        }
        
        @include lg-block {
            left: 50px;
        }

        & h3 {
            @include h5();
            color: var(--color-typo);
            margin: 0 0 32px;
        }
    }

    &__info {
        @include p();
        color: var(--color-typo);
        margin: 0 0 4px;
    }

    &__work {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        margin: 0 0 32px;
    }

    &__work-hours {
        @include p();
        color: var(--color-black);
    }

    &__address {
        @include h5();
        color: var(--color-black);
        margin: 0 0 32px;
    }

    &__phone, &__email {
        @include h5();
        color: var(--color-black);
        text-decoration: none;
    }

    &__phone {
        margin: 0 0 8px;
    }

    &__email {
        margin: 32px 0;
    }

    &__social {
        display: flex;
        gap: 24px;
    }

    &__social-item {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--color-typo);

        & svg use {
            fill: var(--color-typo);
        }
    }

    &__map {
        position: relative;
        z-index: 1;
        margin: 0 0 40px;

        @include md-block {
            margin: 0 0 100px;
        }

        #map-contacts {
            height: 600px;
            border-radius: 15px;
            overflow: hidden;
            margin-top: -37px;

            @include md-block {
                margin-top: 0;
            }
        }
    }

    &__reqs {
        @include row-flex();

        &-inner {
            @include col();
            @include size(12);
            display: flex;
            flex-direction: column;
            align-items: center;

            @include lg-block {
                @include size(8);
                @include shift(2);
            }
        }

        &-text {
            @include h5();
            color: var(--color-typo);
            text-align: center;
        }

        &-copy {
            margin: 20px 0;
        }

        &-data {
            width: 100%;
            padding: 50px;
            border-radius: 30px;
            background: var(--color-bg-light);
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        &-item {
            display: flex;
            flex-direction: column;
            gap: 8px;

            @include md-block {
                flex-direction: row;
            }
        }

        &-value, &-field {
            @include p();

            @include md-block {
                flex: 1 0 50%;
            }
        }
    }
}