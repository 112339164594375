.formatted {
    & h2 {
        @include h5();
        margin: 0 0 24px;
    }

    & {
        h2 ~ h2 {
            margin: 60px 0 24px;
        }
    }

    p {
        @include p();
        margin: 0 0 12px;
    }

    ul {
        padding-left: 24px;
        li {
            list-style: disc;
            margin: 0 0 16px;

            & {
                p + ul {
                    margin: 12px 0 16px;
                }
            }
        }
    }

    ol {
        padding: 0;
        counter-reset: item;

        li {
            display: block;
            padding-left: 24px;
            position: relative;

            & ol li {
                padding-left: 36px;
            }
        }
        li:before {
            @include p();
            position: absolute;
            left: 0;
            content: counters(item, ".") ". ";
            counter-increment: item;
        }
    }

    li {
        @include p();
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin: 0 0 60px;

        thead {
            th {
                @include caption();
                padding: 8px 12px;
                font-weight: bolder;
                color: var(--color-black);
                border-right: 1px solid var(--color-bg-border);
                &:last-child {
                    border-right: 0;
                }
            }
        }

        tbody {
            tr {
                &:nth-of-type(odd) {
                    background: var(--color-bg-light);
                }
            }
            td {
                @include caption();
                padding: 8px 12px;
                border-right: 1px solid var(--color-bg-border);
                &:last-child {
                    border-right: 0;
                }
            }
        }
    }

    &__media {
        border-radius: 15px;
        overflow: hidden;

        @include lg-block {
            @include unshift-left(1, $break-lg);
            @include unshift-right(1, $break-lg);
        }

        & img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
}