.catalog {
    $c: &;
    padding: 30px 0 0;

    &__wrapper {
        @include wrapper();
    }

    &__title {
        @include h5();

        @include md-block {
            @include h4();
        }
    }

    &__inner {
        @include row-flex();
        align-items: flex-start;
        position: relative;
    }

    &__content {
        @include col();
        @include size(12);
        overflow: hidden;

        @include lg-block {
            @include size(9);
        }
    }

    &__filters {
        @include lg-block {
            @include col();
            @include size(3);
            
            & > * {
                margin-left: auto;
            }
        }
    }

    &__list {
        &_view {
            &_grid {
                @include row-flex();

                & #{$c}__item {
                    @include col();
                    @include size(12);
                }

                @include md-block {
                    @include u-row-flex(30px);

                    & #{$c}__item {
                        @include u-col(30px);
                        @include u-size(1, 3, 30px)
                    }
                }

                @include lg-block {
                    @include u-row-flex(30px);

                    & #{$c}__item {
                        @include u-col(30px);
                        @include u-size(1, 4, 30px)
                    }
                }
            }
        }
    }

    &__item {
        margin-bottom: 60px;
    }
}