.reviews {
    padding: 30px 0 0;
    
    &__wrapper {
        @include wrapper();
    }

    &__head {
        margin: 0 0 20px;
    }

    &__header {
        @include h4();
        color: var(--color-black);
    }

    &__content {
        @include lg-block {
            @include row-flex();
        }
    }

    &__sidebar {
        @include lg-block {
            @include col();
            @include size(3);
        }

        &-title {
            @include caption();
            color: var(--color-typo);
            margin: 0 0 8px;
        }
    }

    &__new {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-submit {
            align-self: flex-end;
        }

        &-policy {
            @include caption();
            color: var(--color-typo);

            & a {
                color: inherit
            }
        }
    }

    &__main {
        @include lg-block {
            @include col();
            @include size(9);
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__item {
        border-radius: 10px;

        &_self {
            background: var(--color-bg-light);
        }
    }
}