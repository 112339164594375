:root {
    --color-primary-900: #C90029;
    --color-primary-800: #D71636;
    --color-primary-700: #E4203D;
    --color-primary-600: #F72C44;
    --color-primary-500: #FF3645;
    --color-primary-400: #FE4E5E;
    --color-primary-300: #F0737F;
    --color-primary-200: #F79BA3;
    --color-primary-100: #FFCED8;
    --color-primary-50: #FFECF0;

    --color-success: #27AE60;
    --color-typo-success: #219653;
    --color-bg-success: #C3FDDB;

    --color-error: var(--color-primary-400);
    --color-typo-error: var(--color-primary-400);
    --color-bg-error: var(--color-primary-100);

    --color-info: #2F80ED;
    --color-typo-info: #2F80ED;
    --color-bg-info: #ADEBFF;

    --color-warning: #F2C94C;
    --color-typo-warning: #F2C94C;
    --color-bg-warning: #FFF6DA;

    --color-black: #000;
    --color-bg-dark: #011423;
    --color-bg-light: #F2F3F7;
    --color-bg-border: rgba(156,163,175,.3);
    --color-bg-disabled: #EAEBED;
    --color-white: #fff;

    --color-typo-dark: #001424;
    --color-typo: #9CA3AF;
    --color-typo-disable: #C7CBD3;

    --conic-gradient: conic-gradient(from 120.22deg at 50% 50.09%, #FF7690 0deg, #DE0D2C 360deg);
}